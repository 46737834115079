import { useRef, useState } from 'react';
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
  rem,
  Image,
  Text,
  Space,
  Flex,
  Card,
  Box,
  Title,
  UnstyledButton,
  Select,
  NumberInput,
  AspectRatio,
  Stack
} from '@mantine/core';
import { useDisclosure, useElementSize } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import mainLogo from '../main-logo.png';
import headerIntro from '../header-intro.png';
import pesanSekarang from '../pesan-sekarang.png';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendarEvent, IconChevronDown, IconClock } from '@tabler/icons-react';


const HEADER_HEIGHT = rem(70);

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
  },

  mainLogo: {
    position: 'absolute',
    top: '0',
  },

  FormHeader: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',
    backgroundColor: "#f2b92e",
  },

  FormOrder: {
    position: 'absolute',
    top: rem(400),
    right: rem(320),
    left: rem(320),
    [theme.fn.smallerThan('lg')]: {
      top: rem(275),
      right: rem(220),
      left: rem(220),
    },
    [theme.fn.smallerThan('md')]: {
      top: rem(250),
      right: rem(150),
      left: rem(150),
    },
    [theme.fn.smallerThan('sm')]: {
      top: rem(200),
      right: rem(10),
      left: rem(10),
    },
    [theme.fn.smallerThan('xs')]: {
      top: rem(140),
      right: rem(10),
      left: rem(10),
    },
  },

  titleResponsive: {
    [theme.fn.smallerThan('xs')]: {
      textAlign: 'center',
      fontSize: 'inherit'
    },

  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',
    backgroundColor: "#91392d",

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },


  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(5)} ${rem(2)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.red,
    fontSize: rem(14),
    fontWeight: 800,
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : "#fff",
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: "#fff",
      color: "#9c1e21",
    },
  },
}));

interface HeaderResponsiveProps {
  links: { link: string; label: string; icon: any }[];
}

export function HeaderResponsive({ links }: HeaderResponsiveProps) {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const { classes, cx } = useStyles();

  const ref = useRef<HTMLInputElement>();

  const { width, height } = useElementSize();

  async function submitContact(event: {
    target: any; preventDefault: () => void;
  }) {
    event.preventDefault() // Prevent the redirect 
    let rawTanggal = event.target.tanggal.value as Date;
    let tanggal = formatDate(rawTanggal);
    let lokasi = event.target.lokasi.value as string;
    let jam = event.target.jam.value as string;
    let qty = event.target.qty.value as string;
    window.location.replace('https://wa.me/6281287144066?text=Halo%2C+saya+ingin+order+untuk+tanggal+' + tanggal + '+lokasi+saya+' + lokasi + '+pada+jam+' + jam + '+dengan+quantity+order+sebanyak+' + qty + '+Pcs.+Apakah+bisa?');
  };

  function formatDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }



  const items = links.map((link) => (
    <Link
      key={link.label} to={link.link} className={cx(classes.link, { [classes.linkActive]: active === link.link })} onClick={(event) => {
        // event.preventDefault();
        setActive(link.link);
        close();
      }}>


      <div style={{ display: 'flex' }}>
        <Text>{link.icon}</Text>
        <Space w="xs" />
        <Text>{link.label}</Text>
      </div>

    </Link>

  ));


  return (
    <>
      <Header px="md" height={HEADER_HEIGHT} className={classes.root} >
        <Group className={classes.header}>
          <Link
            to="/" >
            <Image width={200} src={mainLogo} className={classes.mainLogo} />
          </Link>
          <Group spacing={5} className={classes.links}>
            {items}
          </Group>
          <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
          <Transition transition="pop-top-right" duration={200} mounted={opened}>
            {(styles) => (
              <Paper className={classes.dropdown} withBorder style={styles}>
                {items}
              </Paper>
            )}
          </Transition>
        </Group>
      </Header>

      <Box mx="auto" >
        <Flex
          justify="center"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Image
            src={headerIntro}
          />
          <Container>
            <Card className={classes.FormOrder} shadow="sm" padding="lg" radius="md"
              withBorder >
              <Card.Section className={classes.FormHeader} >
                <Container>
                  <Group position="center" mt="md" >
                    <Title order={4} className={classes.titleResponsive}>BINGUNG MAU PESAN UNTUK EVENT ANDA?</Title>
                  </Group>
                  <Group position="center" mb="xs"  >
                    <Title order={5} className={classes.titleResponsive}>Pesan Big Order Sekarang! <Text span c="red.8" inherit>Promo Spesial BUY 1 GET 1 FREE</Text>  </Title>
                  </Group>
                </Container>
              </Card.Section>

              <form onSubmit={submitContact}>
                <Group grow>

                  <DatePickerInput
                    name="tanggal"
                    label="Tanggal"
                    placeholder="Pilih"
                    size="xs"
                    dropdownType="modal"
                    rightSection={<IconCalendarEvent
                      size="1rem" color="gray" />}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                  />
                  <Select
                    name="lokasi"
                    label="Pilih Lokasi"
                    placeholder="Daerah Pengantaran"
                    rightSection={<IconChevronDown size="1rem" color="red" />}
                    style={{ width: rem(120) }}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    data={['Jakarta Utara', 'Jakarta Timur', 'Jakarta Selatan', 'Jakarta Pusat', 'Jakarta Barat', 'Bogor', 'Depok', 'Tangerang', 'Bekasi', 'Luar Jabodetabek']}
                    size="xs"
                    withinPortal
                  />
                  <Select
                    name="jam"
                    label="Jam"
                    placeholder="Pilih Waktu"
                    rightSection={<IconChevronDown size="1rem" color="red" />}
                    style={{ width: rem(120) }}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    data={['10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00', '19.00', '20.00', '21.00']}
                    size="xs"
                    withinPortal
                  />
                  <NumberInput
                    name="qty"
                    min={5}
                    defaultValue={5}
                    placeholder="Quantity"
                    label="Order"
                    size="xs"
                    style={{ width: rem(100) }}
                    stepHoldDelay={500}
                    stepHoldInterval={100}
                  />

                </Group>


                <Group position="center" mt="md" >
                  <UnstyledButton type="submit">
                    <Image maw={150}
                      src={pesanSekarang}
                    />
                  </UnstyledButton>
                </Group>

              </form>
            </Card>
          </Container>

        </Flex>
      </Box>
    </>
  );
}
import './App.css';
import { ActionIcon, rem, Container, Group, Image, Text, createStyles, Dialog, Anchor } from '@mantine/core';
import { HeaderResponsive } from './components/HeaderResponsive';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import MenuPage from './pages/menu';
import LocationPage from './pages/location';
import { IconBrandInstagram, IconBrandLinkedin, IconBrandYoutube, IconBrandWhatsapp } from '@tabler/icons-react';
import bigOrderWhatsapp from './big-order-whatsapp.png';
import Corporate from './pages/corporate';
import logoSquare from './logo-square.png';
import waButton from './wa-button.png';


function App() {

  const useStyles = createStyles((theme) => ({
    footer: {
      marginTop: rem(120),
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 0.1)`,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
      borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
    },

    logo: {
      maxWidth: rem(200),

      [theme.fn.smallerThan('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },

    description: {
      marginTop: rem(5),

      [theme.fn.smallerThan('sm')]: {
        marginTop: theme.spacing.xs,
        textAlign: 'center',
      },
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',

      [theme.fn.smallerThan('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },

    groups: {
      display: 'flex',
      flexWrap: 'wrap',

      [theme.fn.smallerThan('sm')]: {
        paddingTop: '20px'
        // display: 'none',
      },
    },

    wrapper: {
      width: rem(250),

    },

    link: {
      display: 'block',
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
      fontSize: theme.fontSizes.sm,
      paddingTop: rem(3),
      paddingBottom: rem(3),

      '&:hover': {
        textDecoration: 'underline',
      },
    },

    title: {
      fontSize: theme.fontSizes.lg,
      fontWeight: 700,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      marginBottom: `calc(${theme.spacing.xs} / 2)`,
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },

    afterFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing.xl,
      paddingTop: theme.spacing.xl,
      paddingBottom: theme.spacing.xl,
      borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,

      [theme.fn.smallerThan('sm')]: {
        flexDirection: 'column',
      },
    },

    social: {
      [theme.fn.smallerThan('sm')]: {
        marginTop: theme.spacing.xs,
      },
    },
  }));

  const { classes } = useStyles();

  const data = [
    {
      "title": "",
      "links": [
        { "label": <><Text fw={700} c="red.9">PT. Sumber Natural Indonesia</Text></>, "link": "#" },
      ]
    },
    {
      "title": "EVENT / BIG ORDER ?",
      "links": [
        { "label": <Image width={200} src={waButton} />, "link": "https://wa.me/6281287144066?text=Halo" }
      ]
    },
    {
      "title": "",
      "links": [
        { "label": <Text fw={700} c="red.9">Kebijakan Privasi</Text>, "link": "https://goldenlamian.com/kemitraan/kebijakanprivasi/" }
      ]
    }];

  const linkz = [
    // { "link": "/", "label": "LUNCH BOX/BIG ORDER", "icon": ""},
    // { "link": "/", "label": "GOLDEN LAMIAN RESTO", "icon": ""},
    // { "link": "/", "label": "LOCATION", "icon": ""},
    { "link": "https://seven-retail.com/", "label": "OUR GROUP", "icon": "" },
    { "link": "https://www.instagram.com/goldenlamian/", "label": "FOLLOW US", "icon": "" },
    { "link": "https://goldenlamian.com/kemitraan", "label": "KEMITRAAN", "icon": "" },
  ];



  interface FooterLinksProps {
    data: {
      title: string;
      links: { label: string; link: string }[];
    }[];
  }

  return (
    <>
      <HeaderResponsive links={linkz}></HeaderResponsive>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/corporate" element={<Corporate />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/location" element={<LocationPage />} />
      </Routes>

      <Dialog position={{ bottom: 0, right: 20 }}
        radius="sm"
        style={{ width: rem(100) }}
        p={0}
        opened={true}>
        <Anchor href="https://wa.me/6281287144066?text=Hi!+Saya+datang+dari+website+dan+tertarik+order+Lunch+Box+untuk+kebutuhan+Meeting%2F+Event+saya+Apakah+bisa+dijelaskan+lebih+detail+nya%3F&oq=Hi!+Saya+datang+dari+website+dan+tertarik+order+Lunch+Box+untuk+kebutuhan+Meeting%2F+Event+saya+Apakah+bisa+dijelaskan+lebih+detail+nya%3F" target="_blank">
          <Image width={100} src={bigOrderWhatsapp} />
        </Anchor>
      </Dialog>

      <footer className={classes.footer}>
        <Container className={classes.inner}>
          <div className={classes.logo}>
            <Image width={100} src={logoSquare} />
          </div>
          <div className={classes.groups}>
            <div className={classes.wrapper}>
              <Text c="red.9" fw={700}>PT. Sumber Natural Indonesia</Text>
              <Group spacing={0} className={classes.social} position="left" noWrap>
                <ActionIcon size="lg" c="red.9">
                  <Anchor href="https://www.instagram.com/goldenlamian/" c="red.9">
                    <IconBrandInstagram size="1.05rem" stroke={1.5} />
                  </Anchor>
                </ActionIcon>
                <ActionIcon size="lg" c="red.9">
                  <Anchor href="https://www.youtube.com/@goldenlamian8616" c="red.9">
                    <IconBrandYoutube size="1.05rem" stroke={1.5} />
                  </Anchor>
                </ActionIcon>
                <ActionIcon size="lg" c="red.9">
                  <Anchor href="https://www.linkedin.com/company/seven-retail-group" c="red.9">
                    <IconBrandLinkedin size="1.05rem" stroke={1.5} />
                  </Anchor>
                </ActionIcon>
              </Group>
            </div>
          </div>
          <div className={classes.groups}>
            <div className={classes.wrapper}>
              <Text className={classes.title}>EVENT / BIG ORDER</Text>
              <Text<'a'>
                c="green.8"
                style={{ fontSize: '25px' }}
                className={classes.link}
                component="a"
                href="https://wa.me/6281287144066?text=Hi!+Saya+datang+dari+website+dan+tertarik+order+Lunch+Box+untuk+kebutuhan+Meeting%2F+Event+saya+Apakah+bisa+dijelaskan+lebih+detail+nya%3F&oq=Hi!+Saya+datang+dari+website+dan+tertarik+order+Lunch+Box+untuk+kebutuhan+Meeting%2F+Event+saya+Apakah+bisa+dijelaskan+lebih+detail+nya%3F"
              // onClick={(event) => event.preventDefault()}
              >
                <IconBrandWhatsapp size="1.55rem" stroke={1.5} />  0812. 8714. 4066
              </Text>
              <Text<'a'>
                c="green.8"
                style={{ fontSize: '25px' }}
                className={classes.link}
                component="a"
                href="https://wa.me/6285219228407?text=Hi!+Saya+datang+dari+website+dan+tertarik+order+Lunch+Box+untuk+kebutuhan+Meeting%2F+Event+saya+Apakah+bisa+dijelaskan+lebih+detail+nya%3F&oq=Hi!+Saya+datang+dari+website+dan+tertarik+order+Lunch+Box+untuk+kebutuhan+Meeting%2F+Event+saya+Apakah+bisa+dijelaskan+lebih+detail+nya%3F"
              // onClick={(event) => event.preventDefault()}
              >
                <IconBrandWhatsapp size="1.55rem" stroke={1.5} /> 0852. 1922. 8407
              </Text>
              {/* <Text<'a'>
          c="green.8"
          style={{ fontSize: '25px' }} 
          className={classes.link}
          component="a"
          href="https://wa.me/6285778983312?text=Hi!+Saya+datang+dari+website+dan+tertarik+order+Lunch+Box+untuk+kebutuhan+Meeting%2F+Event+saya+Apakah+bisa+dijelaskan+lebih+detail+nya%3F&oq=Hi!+Saya+datang+dari+website+dan+tertarik+order+Lunch+Box+untuk+kebutuhan+Meeting%2F+Event+saya+Apakah+bisa+dijelaskan+lebih+detail+nya%3F"
          // onClick={(event) => event.preventDefault()}
        >
         <IconBrandWhatsapp size="1.55rem" stroke={1.5} /> 0857. 7898. 3312
        </Text> */}
            </div>
          </div>
        </Container>
        <Container className={classes.afterFooter}>
          <Text color="dimmed" size="xs">
            copyright 2023 © PT Sumber Natural Indonesia
          </Text>
        </Container>
      </footer>


    </>
  );
}

export default App;


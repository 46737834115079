import { Carousel } from "@mantine/carousel";
import { Image, Card, Text,  rem, Group, BackgroundImage, Flex, Container, Title,  createStyles, UnstyledButton, Center, Anchor } from "@mantine/core";
import backgroundIntro from'../background-intro.png';
import hundredsOutlets from'../100-outlets.png';
import Autoplay from 'embla-carousel-autoplay';
import buyOneGetOne from'../buy1get1.png';
import bigOrder from'../bigorder.png';
import logoBank from'../logo-bank.png';

import menuA from'../menu-a.png';
import menuB from'../menu-b.png';
import menuC from'../menu-c.png';
import menuD from'../menu-d.png';
import menuE from'../menu-e.png';
import menuF from'../menu-f.png';
import menuG from'../menu-g.png';
import lihatMenu from'../lihat-menu.png';
import waveImg from'../wave.png';

import { useRef } from "react";


const Home = () => {
  const useStyles = createStyles((theme) => ({ 
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    ContainerIntro: {
      paddingTop: rem(200)
      
    },
    titleResponsive: {
      [theme.fn.smallerThan('xs')]: {
        textAlign: 'center',
        fontSize: 'inherit'
        },
  
    },
    
  }));
  const { classes } = useStyles();

  const autoplay = useRef(Autoplay({ delay: 2000 }));

return (
  
    <>
      <BackgroundImage
        src={waveImg}
        radius="sm"
      >
       
       <Container className={classes.ContainerIntro}>
       <Flex
      style={{  height: rem(600) }}
      gap="sm"
      justify="center"
      align="center"
      direction="row"
      wrap="wrap"
    >

<Card shadow="sm" padding="lg" radius="md" withBorder style={{width: 800}}>
<Group position="center" mt="md" mb="xs">
<Image maw={750} mx="auto" src={bigOrder} />
<Image maw={700} mx="auto" src={buyOneGetOne} />
      </Group>
<Group position="center" mt="md" mb="xs">
      </Group>
      <Carousel
      withIndicators
      slideSize="30%"
      slideGap="md"
      loop
      align="start"
      plugins={[autoplay.current]}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}
    >
      <Carousel.Slide> 
       <Image  mx="auto" radius="md" src={menuA} alt="Random image" /> 
       <Flex
      mih={50}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
       <Title order={4} align="center">GOLDEN A</Title>
      <Text size="xs" c="dimmed" align="center">Nasi Hainan Ayam Panggang</Text>
      <Title order={5} c="red.8" align="center"><Text size="sm" span inherit>Rp.</Text>39K</Title>
        </Flex>
      </Carousel.Slide>
      <Carousel.Slide> 
       <Image  mx="auto" radius="md" src={menuB} alt="Random image" /> 
       <Flex
      mih={50}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
       <Title align="center" order={4}>GOLDEN B</Title>
      <Text size="xs" c="dimmed" align="center">Nasi Hainan Ayam Goreng</Text>
      <Title order={5} c="red.8" align="center"><Text size="sm" span inherit>Rp.</Text>39K</Title>
        </Flex>
      </Carousel.Slide>
      <Carousel.Slide> 
       <Image  mx="auto" radius="md" src={menuC} alt="Random image" /> 
       <Flex
      mih={50}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
       <Title order={4} align="center">GOLDEN C</Title>
      <Text size="xs" c="dimmed" align="center">Nasi Putih | Ayam Boneless | Caysim | Siomay | Bawang Goreng</Text>
      <Title order={5} c="red.8" align="center"><Text size="sm" span inherit>Rp.</Text>39K</Title>
        </Flex>
      </Carousel.Slide>
      <Carousel.Slide> 
       <Image  mx="auto" radius="md" src={menuD} alt="Random image" /> 
       <Flex
      mih={50}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
       <Title order={4} align="center">GOLDEN D</Title>
      <Text size="xs" c="dimmed" align="center">Nasi putih | Hong Kong Xtra Spicy Chicken | Saus Cabai | Ocha</Text>
      <Title order={5} c="red.8" align="center"><Text size="sm" span inherit>Rp.</Text>46K</Title>
        </Flex>
      </Carousel.Slide>
      <Carousel.Slide><Image  mx="auto" radius="md" src={menuE} alt="Random image" />
      <Flex
      mih={50}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
      <Title order={4} align="center">GOLDEN E</Title>
      <Text size="xs" c="dimmed" align="center">Nasi putih | Golden Fried Chicken | Saus Cabai | Ocha</Text>
      <Title order={5} c="red.8" align="center"><Text size="sm" span inherit>Rp.</Text>46K</Title>
      </Flex>
      </Carousel.Slide>
      <Carousel.Slide><Image  mx="auto" radius="md" src={menuF} alt="Random image" />
      <Flex
      mih={50}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
      <Title order={4} align="center">GOLDEN F</Title>
      <Text size="xs" c="dimmed" align="center">Lamian Ayam Goreng | Ice Ocha</Text>
      <Title order={5} c="red.8" align="center"><Text size="sm" span inherit>Rp.</Text>46K</Title>
      </Flex>
      </Carousel.Slide>
      <Carousel.Slide>
      <Image  mx="auto" radius="md" src={menuG} alt="Random image" />
      <Flex
      mih={50}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
      <Title order={4} align="center">GOLDEN G</Title>
      <Text size="xs" c="dimmed" align="center">Hong Kong Xtra Spicy Ayam Goreng Lamian | Ocha</Text>
      <Title order={5} c="red.8" align="center">
        <Text size="xs" span inherit>Rp.</Text>46K
        </Title>
        </Flex>
      </Carousel.Slide>
      </Carousel>
    <Group position="center" mt="lg" >
    <Anchor href="https://goldenlamian.com/assets/menu_golden_lamian.pdf" target="_blank">
    <UnstyledButton>
        <Image maw={150}
        src={lihatMenu}
      />
          </UnstyledButton>
      </Anchor>
      </Group>
      </Card>
    </Flex>
       </Container>
     <BackgroundImage
        src={backgroundIntro}
        radius="sm"
      >
    <Flex
      style={{  height: rem(800) }}
      gap="sm"
      justify="center"
      align="center"
      direction="row"
      wrap="wrap"
    >
        <Card shadow="sm" padding="lg" radius="md" withBorder style={{width: 450}}>
      <Card.Section>
      </Card.Section>
      <Group position="center" mt="md" mb="xs">
      <Image   src={hundredsOutlets} />
      </Group>
    </Card>
    </Flex>
    </BackgroundImage>
    </BackgroundImage>
  <Container style={{  marginTop: rem(50) }}>
  <Center  mx="auto" >
  <Title order={6} c="dimmed">Lebih dari <Text span c="orange.4" inherit>24.000 box</Text>  dipesan setiap harinya. </Title>
    </Center>
    <Center  mx="auto">
    <Title order={3} c="red.9" className={classes.titleResponsive}>Customer yang telah mempercayai kami </Title>
    </Center>
      <Flex
      style={{   }}
      gap="lg"
      justify="center"
      align="center"
      direction="row"
      wrap="wrap"
    >
       <Image maw={600} mx="auto" src={logoBank} />
    </Flex>
  </Container>
    </>
);
};
  
export default Home;


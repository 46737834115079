import { Container } from "@mantine/core";
import { GoogleMap, LoadScript } from '@react-google-maps/api';


const LocationPage = () => {
  
  const containerStyle = {
    width: '100%',
    height: '600px'
  };
  
  const center = {
    lat: 38.0,
    lng: -100.0
  };
    

  
return (
  <Container my="md">
 <LoadScript
    googleMapsApiKey="AIzaSyAUCWTA6CQHIn-ro7tRnvZWy5NxjJHwfxk"
  >
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
    >
      { /* Child components, such as markers, info windows, etc. */ }
      <></>
    </GoogleMap>
    </LoadScript>
  </Container>
   

);
};
  
export default LocationPage;


import { Carousel } from "@mantine/carousel";
import {  Container, Grid,  Title, Image, Card, Text,  SimpleGrid, Group, List, Flex, Button, Input, Anchor, Center, Accordion, rem, createStyles} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
  },

  title: {
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));



const Corporate = () => {
  const { classes } = useStyles();

return (
    <>
    <Container my="md" >
      <Grid>
        <Grid.Col span={12}>
        <Image height={80} src="https://line-promo.web.app/assets/logo-sq.jpg" />
            </Grid.Col>
      </Grid>
    </Container>
    <Container my="md">
      <SimpleGrid cols={2} spacing="md" breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
      
        <Grid gutter="md">
          <Grid.Col span={12}>
          <Card shadow="sm" padding="lg" radius="md" withBorder>

      <Group position="apart" mt="md" mb="xs">
        <Text weight={500}>What we sell :</Text>
        {/* <Badge color="pink" variant="light">
          
        </Badge> */}
      </Group>
      <List>
      <List.Item>Catering</List.Item>
      <List.Item>Corporate Event</List.Item>
      <List.Item>Meeting</List.Item>
      <List.Item>Party</List.Item>
      <List.Item>Partnership</List.Item>
    </List>
    </Card>
          </Grid.Col>
        </Grid>
        <Carousel
      mx="auto"
      withIndicators
      dragFree
      slideGap="md"
      align="start"
      height={215}
    >
      <Carousel.Slide > <Image  mx="auto" radius="md" src="https://line-promo.web.app/assets/logo-sq.jpg" alt="Random image" /></Carousel.Slide>
      <Carousel.Slide><Image  mx="auto" radius="md" src="https://line-promo.web.app/assets/logo-sq.jpg" alt="Random image" /></Carousel.Slide>
      <Carousel.Slide><Image  mx="auto" radius="md" src="https://line-promo.web.app/assets/logo-sq.jpg" alt="Random image" /></Carousel.Slide>
      {/* ...other slides */}
    </Carousel>
      </SimpleGrid>
    </Container>
    <Container my="md">
    <Flex
      mih={50}
      bg="rgba(0, 0, 0, .05)"
      gap="md"
      justify="center"
      align="center"
      direction="row"
      wrap="wrap"
    >
      <Input
      placeholder="Name"
    />
    <Input
      placeholder="Company"
    />
    <Input
      placeholder="No Hp"
    />
     <Button type="submit" color="red.9">
                Submit
              </Button>
    </Flex>
    </Container>
    <Container>
      <Center><Text>Or</Text></Center>
    </Container>
    <Container my="md">
    <Flex
      mih={50}
      bg="rgba(0, 0, 0, .05)"
      gap="md"
      justify="center"
      align="center"
      direction="row"
      wrap="wrap"
    >
     <Text>Contact Us</Text>
     <Anchor href="https://api.whatsapp.com/send/?phone=6285778983312&text=Hello+%2C+saya+baru+lihat+website+dan+tertarik+untuk+booking&type=phone_number&app_absent=0" target="_blank">
      @Whatsapp +62857-7898-3312
    </Anchor>
    </Flex>
    </Container>
    <Container>
      <Center> <Button variant="gradient" gradient={{ from: 'orange', to: 'red' }}>Download B2B Menu</Button></Center>
    </Container>
    <Container size="sm" className={classes.wrapper}>
      <Title align="center" className={classes.title}>
      Pertanyaan Seputar Catering
      </Title>
      <Accordion variant="separated">
        <Accordion.Item className={classes.item} value="reset-password">
          <Accordion.Control>Bagaimana mekanisme pemesanan prasmanan atau catering harian Golden Lamian?</Accordion.Control>
          <Accordion.Panel>Silahkan menghubungi tim direct sales melalui email corporate@seven-retail.com atau pada nomer +628161691592 (whatsapp) untuk mengirim pengajuan order ke Golden Lamian.</Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="another-account">
          <Accordion.Control>Apa ada minimal pesanan?</Accordion.Control>
          <Accordion.Panel>Minimal 50 porsi untuk menu regular dan paket catering harian, sedangkan untuk pemesanan paket prasmanan berlaku minimal payment sebesar Rp. 4.500.000</Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="newsletter">
          <Accordion.Control>Berapa lama ketentuan pemesanan catering harian atau prasmanan?</Accordion.Control>
          <Accordion.Panel>Minimal H-14 hari. Apabila ada perubahan dapat diinfokan ke store kami.</Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
    </>
);
};
  
export default Corporate;

